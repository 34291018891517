<template>
  <button @click="handleClick"
          class="inline-flex items-center justify-center px-4 py-1 font-medium duration-100 border rounded-full border-text-gray-100"
          :class="selected === slug ? 'bg-gray-100 text-gray-600' : 'hover:bg-white/10 hover:border-white bg-transparent text-gray-100 hover:text-white'"
  >
    {{ title }}
  </button>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

type CategoryProps = {
  slug: string;
  title: string;
  selected: string;
  queryParam?: string;
  queryParamPage?: string;
};

const props = withDefaults(defineProps<CategoryProps>(), {
  queryParam: 'category',
  queryParamPage: 'page'
});

function handleClick() {
  if (route.query[props.queryParamPage]) {
    router.push({
      query: {
        ...route.query,
        [props.queryParam]: props.slug,
        [props.queryParamPage]: 1
      }
    });
  } else {
    router.push({
      query: {
        ...route.query,
        [props.queryParam]: props.slug
      }
    });
  }
}
</script>
